import React from 'react';
import logo from './logo.svg';



function Header() {

 

  return (
    <div className="App">
      
      <div className="App-header">
        <div className="logo-section">
          <img src={logo} className="App-header__logo" alt="logo" />
          <div className="App-header__number">
            <p>0800 716 966</p>
          </div>
        </div>
        <div className="App-intro-section-wrapper">
          <div className="App-intro-section">
            <p className="App-intro-section__title">EXHIBITION DESIGN AND CONSTRUCTION EXPERTS</p>
            <hr />
            <p className="App-intro-section__text">We’re one of the industry’s leading specialist providers in the design, build and installation of event and exhibition services.</p>
            <a href="#service-bar" className="App-intro-section__button">Learn more  
              <svg width="24px" height="15px" viewBox="0 0 24 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Landing-Header---V1-(Img.4---Full-Page)-Hover" transform="translate(-372.000000, -685.000000)" stroke="#FFFFFF" stroke-width="4">
                          <g id="Content-Card" transform="translate(100.000000, 300.000000)">
                              <g id="Learn-More-CTA" transform="translate(99.000000, 379.000000)">
                                  <g id="Arrow" transform="translate(185.000000, 12.500000) rotate(-90.000000) translate(-185.000000, -12.500000) translate(179.500000, 2.500000)">
                                      <polyline id="Path-11" points="10.0168269 0 0.0168269231 10 10.0168269 20"></polyline>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
