import React, {Component} from 'react';
import logo from './logo.svg';
import { Link } from "react-router-dom";


class ServicesBar extends Component {

  render () {
    return (
      <div className="services-bar">
        <div className="services-bar-container">
          <Link to="/"><img src={logo} className="services-bar-container-logo" alt="logo" /></Link>
          <div className="services-bar-container-contact">
            <div>
            <Link to="/services">Our Services</Link>
            </div>
            <p>|</p>
            <div>
              <a href="tel:0800716966">0800 716 966</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default ServicesBar;
