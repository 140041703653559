import React, {Component} from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import ReCAPTCHA from "react-google-recaptcha";


class Footer extends Component {
    

    state = {
        name: '',
        email: '',
        company: '',
        phone: '',
        desc: '',
        contact: false,
        hasMessage: false,
        messageSent: false,
        captcha: ''
    }

    isEmail = (email) => {
        const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(emailRegEx)) return true
        else return false
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onChange = (value) => {
        this.setState ({
            captcha: value
        })
      
        if (this.isEmail(this.state.email)) {
            this.setState({ contact: true})
            this.setState({contactError: false})
        } else {
            this.setState({contactError: "Please provide at least one form of contact. "})
        }
        if (this.state.desc.length > 0) {
            this.setState({ hasMessage: true})
            this.setState({messageError: false})
        } else {
            this.setState({messageError: "Please provide a message"})
        }

        if (this.state.contact === true && this.state.hasMessage === true && this.state.captcha.length > 0) {
           
            this.sendForm()
        }

    }
      

    handleSubmit = (event, re) => {
        event.preventDefault()
       
        re.current.execute();
      
    }

    sendForm = () => {
       
        fetch( 'https://jbtfnot2ng.execute-api.eu-west-1.amazonaws.com/test/submit', {
            method: 'POST',
            crossDomain: "true",
            dataType: "json",
            headers: {
                'Content-Type': "application/json; charset=utf-8",
            },
            body: JSON.stringify({ 
                name: this.state.name,
                email: this.state.email,
                company: this.state.company,
                phone: this.state.phone,
                desc: this.state.desc
            })
        })
        this.setState({messageSent: true})
    }
   
   

    render() {
        const mapStyles = {
            position: 'relative',
            width: '300px',
            height: '300px',
            left: '50',
            right: '50'
          };
     const recaptchaRef = React.createRef();
        return (
            <div className="footer">
                <div className="footer-top">
                    <p className="footer-top__title">Contact</p>
                    <hr/>
                    <p className="footer-top__text">For further information on all our services please complete the following enquiry form or feel free to take advantage of our freephone number to discuss your requirements in detail with us.</p>
                    <div className="footer-top-address-section">
                        <div className="footer-top-address-section__address">
                            <p>HIREX</p>
                            <p>Lomax Street,</p>
                            <p>Radcliffe,</p>
                            <p>Manchester</p>
                            <p>M26 1PX</p>
                            <a href="tel:0800716966">0800 716 966</a>
                            <a href="mailto:sales@hirex.co.uk">sales@hirex.co.uk</a>
                        </div>
                        <div className="footer-map">
                            <Map
                                google={this.props.google}
                                containerStyle={{position: 'relative',
                                                width: '300px',
                                                top: '45px'}}
                                                zoom={17}
                                                style={mapStyles}
                                                initialCenter={{ lat: 53.556175, lng: -2.328252}}
                                               
                                >
                                 <Marker position={{ lat: 53.556175, lng: -2.328252}} />
                            </Map>
                        </div>
                        {this.state.messageSent ?
                        <div>
                            <p>Thank you for your message. Someone will be in contact with you soon</p>
                        </div>
                        :
                        <form onSubmit={(event) => this.handleSubmit(event, recaptchaRef) }  >
                            <input required="true" type="text" name="name" placeholder="Your Name" onChange={this.handleChange}/>
                            <label for="name" className="label-name"></label>
                            <input required="true" type="email" name="email" placeholder="Email" onChange={this.handleChange}/>
                            <label for="email" className="label-email"></label>
                            <input required="true" type="text" name="company" placeholder="Company" onChange={this.handleChange}/>
                            <label for="company" className="label-company"></label>
                            <input required="true" type="tel" name="phone" placeholder="Telephone" onChange={this.handleChange}/>
                            <label for="tel" className="label-tel"></label>
                            <textarea  required="true" className="enquiry" placeholder="Your Enquiry" wrap="hard" name="desc" onChange={this.handleChange}></textarea>
                            <label for="desc" className="label-enquiry"></label>
                           
                            <button type="submit">
                            <p>Submit</p>  
                            <svg width="24px" height="15px" viewBox="0 0 24 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Landing-Header---V1-(Img.4---Full-Page)-Hover" transform="translate(-372.000000, -685.000000)" stroke="#FFFFFF" stroke-width="4">
                                        <g id="Content-Card" transform="translate(100.000000, 300.000000)">
                                            <g id="Learn-More-CTA" transform="translate(99.000000, 379.000000)">
                                                <g id="Arrow" transform="translate(185.000000, 12.500000) rotate(-90.000000) translate(-185.000000, -12.500000) translate(179.500000, 2.500000)">
                                                    <polyline id="Path-11" points="10.0168269 0 0.0168269231 10 10.0168269 20"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            </button>
                            {this.state.contactError ?
                               this.state.contactError
                               :
                               <div></div>
                            }
                            {this.state.messageError ?
                               this.state.messageError
                               :
                               <div></div>
                            }
                        </form>
                        }
                    </div>
                </div>
                 <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LcdZaYZAAAAAKdTtfcvYere9cv1ruJhszqbv8kw"
                    onChange={this.onChange}
                    />
                <div className="footer-bottom">
                    <div className="footer-bottom-container">
                        <p>&copy; Hirex Ltd 2022 | Terms &amp; Privacy</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper({
   apiKey: 'AIzaSyCEoaUgYItWypoAhRDbP8tAu0Iz8cswj28'
  })(Footer)
